<template>
  <div class="c-leaderboard-table"
       style="background: radial-gradient(178.90% 78.61% at 47.02% 0%, #00AC91 0%, #0053BE 100%);"
  >
    <div class="c-leaderboard-table__intro">
      <h3 class="c-leaderboard-table__title">
        Leaderboard games 🎲

        <div class="c-leaderboard-table__timer">
          <div>
            Resets in
            {{ ($store.getters.getMillisecondsRemainingThisWeek > 3600000 ? Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 / 60 / 60).toString().padStart(2, "0") + ":" : "") + ($store.getters.getMillisecondsRemainingThisWeek > 60000 ? Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 / 60 % 60).toString().padStart(2, "0") + ":" : "") + Math.floor($store.getters.getMillisecondsRemainingThisWeek / 1000 % 60).toString().padStart(2, "0") }}
          </div>
        </div>
      </h3>
      <div class="c-leaderboard-table__pool-line">
        <span class="c-leaderboard-table__subtitle">
          💰 Prize pool
        </span>
        <span class="c-leaderboard-table__pool" style="color: #f3e419;">
          {{ $filters.formatNumber($store.getters.getGamesLeaderboardPrize) }} Chips
        </span>
      </div>
      <div class="c-leaderboard-table__pool-update">
        <button
          class="c-leaderboard-table__pool-update-btn"
          :class="{ 'c-btn--c-leaderboard-table__pool-update-btn-selected': gameLeaderboardCategory === 0 }"
          @click="selectLeaderboard(0)"
        >
          Leaderboard
        </button>
        <button
          class="c-leaderboard-table__pool-update-btn"
          :class="{ 'c-btn--c-leaderboard-table__pool-update-btn-selected': gameLeaderboardCategory === 1 }"
          @click="selectLeaderboard(1)"
        >
          Completed games
        </button>
      </div>
    </div>
    <div v-if="gameLeaderboardCategory===0" class="c-leaderboard-table__ctn">
      <div class="c-leaderboard-table__head">
        <div class="c-leaderboard-table__row">
          <div class="c-leaderboard-table__row-item is-for-number"></div>
          <div class="c-leaderboard-table__row-item is-for-bettor" style="color: white; opacity: 1;">
            User
          </div>
          <div class="c-leaderboard-table__row-item is-for-points" style="color: white; opacity: 1;">
            Points
          </div>
          <div class="c-leaderboard-table__row-item is-for-prize" style="color: white; opacity: 1;">
            Prize
          </div>
        </div>
      </div>
      <div class="c-leaderboard-table__body">
        <div
          v-for="(item, index) in leaderboard.users"
          :key="index"
          :class="['c-leaderboard-table__row']"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div
              :class="[
                'c-circle c-circle--gray',
                {
                  'c-circle--is-for-me':
                    item.username == $store.getters.getDiscordUsername || item.username == $store.getters.getUserId,
                },
              ]"
            >
              <span class="c-circle__value">{{ (index + 1) }}</span>
            </div>
          </div>

          <img v-if="item.avatar"
               :src="/\/\d$/.test(item.avatar)?item.avatar+'.png':item.avatar"
               class="c-leaderboard-table__row-item-avatar"
               alt=""
               onError="this.onerror=null;this.src='https://cdn.discordapp.com/embed/avatars/4.png';"
          />
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
            :class="{'c-leaderboard-table__row-item-with-avatar':item.avatar}"
          >
            {{ item.username }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ $filters.formatNumber( item.points  ) }}
          </div>
          <div
            :class="[
              'c-leaderboard-table__row-item is-for-prize',
              { 'is-green': item.prize },
            ]"
          >
            {{ $filters.formatNumber( getPrize(index)  ) }}
          </div>
        </div>
        <div
          v-if="$store.getters.getUsername && !isTop10 && $store.getters.getLoginData.loginMethod !== 'guest'"
          class="c-leaderboard-table__row is-for-me"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div class="c-circle c-circle--gray c-circle--is-for-me">
              <span class="c-circle__value">{{ "-" }}</span>
            </div>
          </div>
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
          >
            {{ $store.getters.getDiscordUsername? $store.getters.getDiscordUsername : $store.getters.getUserId }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ $filters.formatNumber( leaderboard.myPoints  ) }}
          </div>
          <div class="c-leaderboard-table__row-item is-green is-for-prize">
            {{ "-" }}
          </div>
        </div>
        <div
          v-else-if="$store.getters.getIsGuest"
          class="c-leaderboard-table__row is-for-me"
          style="font-family: 'Rubik', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 15px;
                line-height: 130%;
                text-align: center;
                cursor:pointer;
                letter-spacing: -0.03em;"
          @click="$store.commit('setGuestGuideOpen', true)"
        >
          Signup to join the leaderboard and win extra chips
        </div>
      </div>
      <div class="c-leaderboard-table__category">
        <div
          v-if="!showCurrent"
          class="c-leaderboard-table__pool-previous-btn"
          @click="selectCurrentLeaderboard(true)"
        >
          See current Week
        </div>
        <div
          v-if="showCurrent"
          class="c-leaderboard-table__pool-previous-btn"
          @click="selectCurrentLeaderboard(false)"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42394 2.57582C9.31142 2.46334 9.15884 2.40015 8.99974 2.40015C8.84064 2.40015 8.68805 2.46334 8.57554 2.57582L5.57554 5.57582C5.46305 5.68834 5.39987 5.84092 5.39987 6.00002C5.39987 6.15912 5.46305 6.3117 5.57554 6.42422L8.57554 9.42422C8.6887 9.53351 8.84026 9.59399 8.99758 9.59262C9.1549 9.59126 9.30538 9.52815 9.41663 9.41691C9.52787 9.30567 9.59098 9.15518 9.59234 8.99786C9.59371 8.84054 9.53323 8.68898 9.42394 8.57582L6.84814 6.00002L9.42394 3.42422C9.53642 3.3117 9.59961 3.15912 9.59961 3.00002C9.59961 2.84092 9.53642 2.68834 9.42394 2.57582ZM5.82394 2.57582C5.71142 2.46334 5.55884 2.40015 5.39974 2.40015C5.24064 2.40015 5.08805 2.46334 4.97554 2.57582L1.97554 5.57582C1.86305 5.68834 1.79987 5.84092 1.79987 6.00002C1.79987 6.15912 1.86305 6.3117 1.97554 6.42422L4.97554 9.42422C5.0887 9.53351 5.24026 9.59399 5.39758 9.59262C5.5549 9.59126 5.70538 9.52815 5.81663 9.41691C5.92787 9.30567 5.99097 9.15518 5.99234 8.99786C5.99371 8.84054 5.93323 8.68898 5.82394 8.57582L3.24814 6.00002L5.82394 3.42422C5.93642 3.3117 5.99961 3.15912 5.99961 3.00002C5.99961 2.84092 5.93642 2.68834 5.82394 2.57582Z" fill="#FFE500"/>
          </svg>

          See previous Week
        </div>
      </div>
    </div>
    <div v-if="gameLeaderboardCategory===1" style="display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;">
      <div class="games-leaderboard-info" style="white-space: pre-wrap">
        You earn <span style="color: #FFE500">1 point</span> for each different game that you spinned {{ $store.getters.getGamesLeaderboardBetsPerGame }} times with a minimum bet of {{ $store.getters.getGamesLeaderboardMinimunBet }} chips.
        {{'\n'}}You receive <span style="color: #FFE500">1 Silver</span> raffle ticket when you complete all the games.
      </div>
      <div class="l-home__games-grid">
        <div
          v-for="slotGame in getGames()"
          :key="slotGame.name"
          :style="isJackbotPage&&!isMobile?'flex: 1 1 18%;width: 16%; max-width: 16%;':
          page==='crash'?'':''"
          :class="{ 'l-home__games-grid-item-crash':page==='crash'||page==='spincity' ,
                  'l-home__games-grid-item':page!=='crash'&&page!=='spincity' }"
          @click="openGame(slotGame.name)"
        >
          <div style="position: relative">
            <div v-if="!isGameAccomplished(slotGame.name)" style="width: 100%;
            height: 100%;
            background: rgba(125,133,141,0.5);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            cursor:pointer;"
                 @click="openGame(slotGame.name)"></div>
            <svg v-if="isGameAccomplished(slotGame.name)" style="position: absolute; left: 0; top: 0;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6092_1130)">
                <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_6092_1130)"/>
                <g clip-path="url(#clip1_6092_1130)">
                  <path d="M15.6485 7.68413L9.31801 14.0142C8.84923 14.4831 8.08879 14.4831 7.61957 14.0142L4.35172 10.7461C3.88276 10.2772 3.88276 9.51671 4.35172 9.04775C4.82077 8.5787 5.58115 8.5787 6.04999 9.04757L8.46904 11.4667L13.95 5.98569C14.419 5.51664 15.1795 5.51699 15.6483 5.98569C16.1172 6.45465 16.1172 7.2149 15.6485 7.68413Z" fill="white"/>
                </g>
              </g>
              <defs>
                <linearGradient id="paint0_linear_6092_1130" x1="-2.88462" y1="-7" x2="30.4574" y2="-3.97291" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0CFF7C"/>
                  <stop offset="1" stop-color="#00AF90"/>
                </linearGradient>
                <clipPath id="clip0_6092_1130">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
                <clipPath id="clip1_6092_1130">
                  <rect width="12" height="12" fill="white" transform="translate(4 4)"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-else style="position: absolute; left: 0; top: 0;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6092_632)">
                <circle cx="10" cy="10" r="10" fill="#7D858D"/>
                <path d="M5.27955 5.27955C5.4586 5.10055 5.70141 5 5.95459 5C6.20776 5 6.45057 5.10055 6.62962 5.27955L10.0058 8.65569L13.3819 5.27955C13.562 5.10563 13.8032 5.00939 14.0535 5.01156C14.3038 5.01374 14.5433 5.11415 14.7203 5.29118C14.8974 5.46821 14.9978 5.70768 15 5.95802C15.0021 6.20837 14.9059 6.44955 14.732 6.62962L11.3558 10.0058L14.732 13.3819C14.9059 13.562 15.0021 13.8032 15 14.0535C14.9978 14.3038 14.8974 14.5433 14.7203 14.7203C14.5433 14.8974 14.3038 14.9978 14.0535 15C13.8032 15.0021 13.562 14.9059 13.3819 14.732L10.0058 11.3558L6.62962 14.732C6.44955 14.9059 6.20837 15.0021 5.95802 15C5.70768 14.9978 5.46821 14.8974 5.29118 14.7203C5.11415 14.5433 5.01374 14.3038 5.01156 14.0535C5.00939 13.8032 5.10563 13.562 5.27955 13.3819L8.65569 10.0058L5.27955 6.62962C5.10055 6.45057 5 6.20776 5 5.95459C5 5.70141 5.10055 5.4586 5.27955 5.27955Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_6092_632">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <img
              :src="getIconPath(slotGame.thumb)"
              alt=""
              :style="isGameAccomplished(slotGame.name)?
              'border-radius: 10px; width: 100%; border: solid #0CFF7C 2px;':
              'width: 100%; border: solid #7D858D 2px; border-radius: 10px;'"
            >
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { allGames } from "../../constants/index";

export default {
  name: "GamesTable",
  components: {
  },
  data() {
    return {
      allGames,
      isJackbotPage:false,
      isMobile:false,
      isTest:false,
      showCurrent: true,
      showInfo:false,
      page:"slot",
      leaderboardCategory: 2,
      gameLeaderboardCategory: 0,
    };
  },
  mounted() {
    // let url = window.location.hostname;
    // if(url.includes("test.") || url.includes("localhost")) {
    //   this.isTest = true;
    // }
  },
  computed: {
    games() {
      let myGames = { ...this.allGames };

      if(!this.isTest) {
        for (let gameName of Object.keys(myGames)) {
          if (myGames[gameName].testing || myGames[gameName].isSoon ) {
            delete myGames[gameName];
          }
        }
      }

      return myGames;
    },
    leaderboardPrize() {
      switch (this.leaderboardCategory) {
        case 0:
          return this.$store.getters.getLeaderboardPrizeHourly;
        case 1:
          return this.$store.getters.getLeaderboardPrizeDaily;
        case 2:
          return this.$store.getters.getLeaderboardPrizeWeekly;
      }
      return 0;
    },
    leaderboard() {
      if (!this.$store.getters.getLeaderboard) {
        return { users: [], myPoints: 0,
          myProgress:{} };
      }

      let lb = this.$store.getters.getLeaderboard;
      return this.showCurrent? lb.current.games : lb.previous.games;
    },
    isTop10() {
      if (!this.leaderboard) {
        return false;
      }
      for (let i = 0; i < this.leaderboard.users.length; i++) {
        if (
          this.leaderboard.users[i].username == this.$store.getters.getDiscordUsername || this.leaderboard.users[i].username == this.$store.getters.getUserId
        ) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    //this.$store.dispatch("getLeaderboard");
  },
  methods:{
    selectLeaderboard(category) {
      this.gameLeaderboardCategory = category;
      this.showCurrent=true;
    },
    selectCurrentLeaderboard(bool) {
      this.showCurrent = bool;
    },
    getPrize(i) {
      return this.$store.getters.getLeaderboardPrizeSplit.length > i? this.$store.getters.getGamesLeaderboardPrize * this.$store.getters.getLeaderboardPrizeSplit[i] / 100 : 0;
    },
    getGames(){
      const gamesNew=[];
      const gamesOpen=[];
      const gamesLocked=[];
      Object.keys(this.games).forEach(game => {
        if(this.games[game].isNew && !this.games[game].isSoon) {
          gamesNew.push(this.games[game])
          return;
        }
        if(this.isGameLocked(game) && !this.games[game].isNew) {
          gamesLocked.push(this.games[game]);
        }
        else{
          gamesOpen.push(this.games[game]);
        }
      })
      gamesNew.sort((a, b) => a.order - b.order);
      gamesOpen.sort((a, b) => a.order - b.order);
      gamesLocked.sort((a, b) => a.order - b.order);
      const output = [];
      gamesNew.forEach(game =>{output.push(game);})
      gamesOpen.forEach(game =>{output.push(game);})
      gamesLocked.forEach(game =>{output.push(game);})
      let gamesCount = this.isJackbotPage&&this.isMobile?6:100
      gamesCount=Math.min(gamesCount,output.length);
      return output.splice(0,gamesCount);
    },
    isGameLocked(name){
      if(name==="tycoon")return false
      return /*this.isTest&&this.page==="scratch"?false:*/this.$store.getters.getUsername?!this.$store.getters.getUnlockedGames.includes(name):false;
    },

    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
    openGame(name){
      if(this.games[name].isSoon && !(
        location.hostname.includes("localhost") ||
        location.hostname.includes("test."))
      )
        return;
      if(!this.$store.getters.getUsername){
        this.signUpPopup=true;
        return;
      }
      if(this.isGameLocked(name)){
        this.notEnoughGems = false;
        this.clickedGame=name;
        this.unlockGamePopup = true;
      } else {
        this.$router.push({ path: `/${this.page==='scratch'?'scratch':
            this.page==='crash'?'crash':
              this.page==='instant'?'instant':
                this.page==='spincity'?'spincity':
                  'game'}/${name}`, query: this.$route.query })
      }
    },
    isGameAccomplished(game){
      return this.leaderboard.myProgress &&
        this.leaderboard.myProgress[game] &&
        this.leaderboard.myProgress[game] >= this.$store.getters.getGamesLeaderboardBetsPerGame
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/filter-date";
@import "../../assets/styles/components/app-circle";
@import "../../assets/styles/components/leaderboard-table";
.c-leaderboard-table__head{
  opacity: 1;
}
.c-leaderboard-table__row.is-for-me{
  color: white;
}
.c-leaderboard-table__row-item{
  color: white;
}
.info-bubble-popup {
  width: 240px;
  height: min-content;
  z-index: 1;
  top: 60px;
}
.c-leaderboard-table__pool-update-btn{
  width: fit-content;
  padding: 5px 13px;
}
.c-leaderboard-table__pool-update{
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.games-leaderboard-info{
  text-align: center;
  /* Desktop/Text/Tertiary */
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  letter-spacing: -0.36px;
}
.l-home__games-grid-item{
  width: 87px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.l-home__games-grid{
  display: flex;
  width: 378px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
