<template>
  <div class="popup-container">
    <div style="height: 100%; width: 100%; position: absolute;" @click="closeGuide"></div>
    <div class="popup-popup">
      <img
        src="../../assets/svg-icons/svgchipyellow6391-onv9.svg"
        alt="svgchipyellow6391"
        class="popup-svgchipyellow"
      />
      <img
        src="../../assets/svg-icons/svgchipgreen6391-19tm.svg"
        alt="svgchipgreen6391"
        class="popup-svgchipgreen"
      />
      <div class="popup-howtowin">
        <span class="popup-text TitleModal">
          <span>How to win free CHIPS?</span>
        </span>
        <div class="popup-cards">
          <div class="popup-frame">
            <div class="popup-icon">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-lyek.svg"
                class="popup-pasted-image"
              />
            </div>
            <div class="popup-text02">
              <span class="popup-text03 TitleH4">
                <span>Connect everyday</span>
              </span>
              <span class="popup-text05 TextSecondary">
                <span>to increase your daily free chips reward</span>
              </span>
            </div>
          </div>
          <div class="popup-frame">
            <div class="popup-icon1">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-1qop.svg"
                class="popup-pasted-image1"
              />
            </div>
            <div class="popup-text07">
              <span class="popup-text08 TitleH4">
                <span>
                  Levelling up
                  <span v-html="raw5dbd"></span>
                </span>
              </span>
              <span class="popup-text10 TextSecondary">
                <span>
                  also earns you more chips, so keep playing and advancing to
                  unlock new rewards
                </span>
              </span>
            </div>
          </div>
          <div class="popup-frame"  @click="openAchievements">
            <div class="popup-icon2">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-q1t5.svg"
                class="popup-pasted-image2"
              />
            </div>
            <div class="popup-text12">
              <span class="popup-text13 TitleH4">
                <span>Collect achievements</span>
              </span>
              <span class="popup-text15 TextSecondary">
                <span>
                  and win up to 1 million chips. Example playing same slot machine
                  20,000 times
                </span>
              </span>
            </div>
          </div>
          <div class="popup-frame" @click="openLink('https://zealy.io/c/blockspingaming/questboard')">
            <div class="popup-icon3">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-p9t.svg"
                class="popup-pasted-image3"
              />
            </div>
            <div class="popup-text17">
              <span class="popup-text18 TitleH4">
                <span>Participate in daily quests</span>
              </span>
              <span class="popup-text20 TextSecondary">
                <span>
                  on Zealy.io to earn additional opportunities to win free chips
                  and other rewards
                </span>
              </span>
            </div>
          </div>
          <div class="popup-frame" @click="openReferral">
            <div class="popup-icon4">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-owd8.svg"
                class="popup-pasted-image4"
              />
            </div>
            <div class="popup-text22">
              <span class="popup-text23 TitleH4"><span>Refer friends</span></span>
              <span class="popup-text25 TextSecondary">
                <span>to the game and earn even more chips to play with</span>
              </span>
            </div>
          </div>
          <div class="popup-frame" @click="openLink('https://linktr.ee/blockspingaming');">
            <div class="popup-icon5">
              <img
                alt="pastedImage"
                src="../../assets/svg-icons/pastedimage-fq5.svg"
                class="popup-pasted-image5"
              />
            </div>
            <div class="popup-text27">
              <span class="popup-text28 TitleH4">
                <span>Follow our social media</span>
              </span>
              <span class="popup-text30 TextSecondary">
                <span>
                  where we regularly drop redeem codes for free chips and do
                  giveaways
                </span>
              </span>
            </div>
          </div >
        </div>
        <div @click="closeGuide">
          <img :src="require('../../assets/svg-icons/icon_close.svg')"
               class="c-header-profile-close"
               alt=""/>
        </div>
      </div>
      <img
        src="../../assets/svg-icons/svgchipviolet6391-2icy.svg"
        alt="svgchipviolet6391"
        class="popup-svgchipviolet"
      />
      <img
        src="../../assets/svg-icons/svgchipblue6391-0uzj.svg"
        alt="svgchipblue6391"
        class="popup-svgchipblue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipsGuide',
  props: {},
  data() {
    return {
      raw5dbd: ' ',
    }
  },
  methods:{

    async closeGuide(){
      //this.guidePopup=false;
      await this.$store.commit("setGuideOpen", false)
    },
    openReferral(){
      this.closeGuide().then(()=>{
        if(this.$store.getters.getUsername) {
          this.$store.commit("setReferralPopupOpen", true);
        }else{
          this.$store.commit("setSignUpOpen", true);
        }
      })
    },
    openAchievements(){
      const state=this.$store.state;
      //this.guidePopup=false;
      state.isChipsGuideOpen = false;
      state.isGuideOpen = false;
      state.isAchievementsPopupOpen=true;
    },
    openLink(link){
      window.open(link)
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.popup-popup {
  width: 800px;
  height: 694px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.popup-svgchipyellow {
  top: 308.49395751953125px;
  left: 670.8143920898438px;
  width: 117px;
  height: 78px;
  position: absolute;
}
.popup-svgchipgreen {
  top: 380.33184814453125px;
  left: 108.93254089355469px;
  width: 100px;
  height: 114px;
  position: absolute;
}
.popup-howtowin {
  gap: 30px;
  top: 27.999818801879883px;
  left: 64.81437683105469px;
  width: 670px;
  display: flex;
  padding: 40px 40px 50px;
  position: absolute;
  box-shadow: 0px 0px 60px 0px rgba(25, 201, 243, 0.4000000059604645) ;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gradients-blue);
  background-image: linear-gradient(152deg, rgba(0, 198, 251, 1) 0%, rgba(0, 91, 234, 1) 100%);
}
.popup-text {
  color: var(--dl-color-default-white);
  width: 450px;
  height: auto;
  text-align: center;
  line-height: 114.99999761581421%;
}
.popup-cards {
  gap: 10px;
  width: 590px;
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-shrink: 0;
}
.popup-icon {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image {
  width: 42px;
  height: 42px;
}
.popup-text02 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text03 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text05 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-icon1 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image1 {
  width: 42px;
  height: 42px;
}
.popup-text07 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text08 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text10 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-icon2 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image2 {
  width: 42px;
  height: 42px;
}
.popup-text12 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text13 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text15 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-frame {
  gap: 10px;
  width: 290px;
  height: 147px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: var(--dl-color-background-white10);

  cursor: pointer;
  transition: 0.5s ease;
  &:hover{
    background: rgba(255, 255, 255, 0.2);
    filter: drop-shadow(0px 0 10px rgba(255, 255, 255, 0.4));
    transform: scale(1.05);
  }
}
.popup-icon3 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image3 {
  width: 42px;
  height: 42px;
}
.popup-text17 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text18 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text20 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-icon4 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image4 {
  width: 42px;
  height: 42px;
}
.popup-text22 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text23 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text25 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-icon5 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.popup-pasted-image5 {
  width: 42px;
  height: 42px;
}
.popup-text27 {
  gap: 5px;
  width: 198px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.popup-text28 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.popup-text30 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.popup-iconclose {
  top: 30px;
  left: 616px;
  width: 24px;
  height: 24px;
  opacity: 0.30;
  z-index: 2;
  position: absolute;
}
.popup-svgchipviolet {
  top: 62.198062896728516px;
  left: 118.81437683105469px;
  width: 79px;
  height: 62px;
  position: absolute;
}
.popup-svgchipblue {
  top: 584.1882934570312px;
  left: 495.9940490722656px;
  width: 98px;
  height: 63px;
  position: absolute;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.TitleH1 {
  font-size: 148px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 900;
  font-stretch: normal;
}
.TitleH1Mobile {
  font-size: 40px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 900;
  font-stretch: normal;
}
.TitleH2 {
  font-size: 42px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH2Mobile {
  font-size: 24px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH3 {
  font-size: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 800;
  font-stretch: normal;
}
.TitleH3Mobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH4 {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH4Mobile {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleModal {
  font-size: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleModalMobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TextSubtitle {
  font-size: 32px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.TextSubtitleMobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.TextAccent {
  font-size: 18px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBody {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBodyMobile {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBodyLight {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 300;
  font-stretch: normal;
}
.TextBodyLightMobile {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 300;
  font-stretch: normal;
}
.TextSecondary {
  font-size: 14px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextTertiary {
  font-size: 12px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.ButtonMain {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.ButtonSecondary {
  font-size: 14px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}

</style>
