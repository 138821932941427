const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
const botUriL = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://t.me/blockspintest_bot/' : 'https://t.me/BlockSpinBot/';

const achCnt='20,000';

export const GameUri=gameUri;
export const botUri=botUriL;

export const languages = [
  {
    icon: 'flag-en.webp',
    label: 'EN',
    type: 'en',
  },
  /*{
    icon: 'flag-cn.webp',
    label: 'CN',
    type: 'cn',
  }*/
];

export const currencies = [
  {
    icon: 'icon-eos.webp',
    label: 'EOS',
    type: 'EOS'
  },
  {
    icon: 'icon-kylin.webp',
    label: 'KYLIN',
    type: 'KYLIN'
  },
  {
    icon: 'icon-wax.svg',
    label: 'WAX',
    type: 'WAX'
  },
  {
    icon: 'icon-sol.svg',
    label: 'SOL',
    type: 'SOLANA'
  },
  {
    icon: 'icon_polygon.svg',
    label: 'POL',
    type: 'Polygon'
  },
  {
    icon: 'icon_bnb.svg',
    label: 'BNB',
    type: 'BNB'
  },
];

export const wallets = [
  {
    name: 'scatter',
    displayName: 'Scatter'
  },
  {
    name: 'anchor',
    displayName: 'Anchor'
  },
];

export const userSettings = [
  {
    icon: 'icon-wallet',
    label: 'Wallet',
    type: 'wallet',
  },
  {
    icon: 'logout',
    label: 'Logout',
    type: 'logout',
  },
];

export const allGames = {
  kingarthur: {
    title: 'King Arthur',
    name: 'kingarthur',
    uri: gameUri + 'king-arthur',
    description:
        'kingarthur_desc',
    image: 'king-arthur.webp',
    thumb: 'king-arthur-thumb.webp',
    achievement: 'excalibur',
    isNew: false,
    battle: true,
    order: 1,
  },
  wildwest: {
    title: 'Wild West',
    name: 'wildwest',
    uri: gameUri + 'wild-west',
    description:
        'wildwest_desc',
    image: 'wildwest.webp',
    thumb: 'wildwest-thumb.webp',
    achievement: 'outlaw',
    isNew: false,
    battle: true,
    order: 5
  },
  nightoffear: {
    title: 'Night of Fear',
    name: 'nightoffear',
    uri: gameUri + 'night-of-fear',
    description:
        'nightoffear_desc',
    image: 'night-of-fear.webp',
    thumb: 'night-of-fear-thumb.webp',
    achievement: 'ghost',
    isNew: false,
    battle: true,
    order: 4
  },
  cartel: {
    title: 'Cartel',
    name: 'cartel',
    uri: gameUri + 'cartel',
    description:
        'cartel_desc',
    image: 'cartel.webp',
    thumb: 'cartel-thumb.webp',
    achievement: 'kingpin',
    isNew: false,
    battle: true,
    order: 3
  },
  tigerland: {
    title: 'Tiger Land',
    name: 'tigerland',
    uri: gameUri + 'tigerland',
    description:
        'tigerland_desc',
    image: 'tigerland.webp',
    thumb: 'tigerland-thumb.webp',
    achievement: 'zhuhou',
    isNew: false,
    battle: true,
    order: 2
  },
  cleopatra: {
    title: 'Cleopatra',
    name: 'cleopatra',
    uri: gameUri + 'cleopatra',
    description:
        'cleopatra_desc',
    image: 'cleopatra.webp',
    thumb: 'cleopatra-thumb.webp',
    achievement: 'scarabs',
    isNew: false,
    battle: true,
    order: 6
  },
  spymaster: {
    title: 'Spymaster',
    name: 'spymaster',
    uri: gameUri + 'spymaster',
    description:
        'spymaster_desc',
    image: 'spymaster.webp',
    thumb: 'spymaster-thumb.webp',
    achievement: 'topsecret',
    isNew: false,
    battle: true,
    order: 7
  },
  samurai: {
    title: 'Samurai Spirit',
    name: 'samurai',
    uri: gameUri + 'samurai-spirit',
    description:
        'samurai_desc',
    image: 'samurai.webp',
    thumb: 'samurai-thumb.webp',
    achievement: 'shogun',
    isNew: false,
    battle: true,
    order: 8
  },
  surfin: {
    title: 'Surfin\' California',
    name: 'surfin',
    uri: gameUri + 'surfin',
    description:
        'surfin_desc',
    image: 'surfin.webp',
    thumb: 'surfin-thumb.webp',
    achievement: 'surfer',
    isNew: false,
    battle: true,
    order: 9
  },
  pirates: {
    title: 'Treasure Island Quest',
    name: 'pirates',
    uri: gameUri + 'treasure-island',
    description:
        'pirates_desc',
    image: 'pirates.webp',
    thumb: 'pirates-thumb.webp',
    achievement: 'pirate',
    isNew: false,
    battle: true,
    order: 10
  },
  thebigfive: {
    title: 'The Big Five',
    name: 'thebigfive',
    uri: gameUri + 'thebig5',
    description:
        'thebigfive_desc',
    image: 'thebigfive.webp',
    thumb: 'thebigfive-thumb.webp',
    achievement: 'jungleking',
    isNew: false,
    battle: true,
    order: 11
  },
  aladdin: {
    title: 'Aladdin',
    name: 'aladdin',
    uri: gameUri + 'aladdin',
    description:
        'aladdin_desc',
    image: 'aladdin.webp',
    thumb: 'aladdin-thumb.webp',
    achievement: 'genie',
    isNew: false,
    battle: true,
    order: 12
  },
  vegasclassic: {
    title: 'Vegas Classic',
    name: 'vegasclassic',
    uri: gameUri + 'vegasclassic',
    description:
        'vegas_desc',
    image: 'vegas.webp',
    thumb: 'vegas-thumb.webp',
    achievement: 'progambler',
    isNew: false,
    battle: true,
    order: 13,
  },
  route66: {
    title: 'Route 66',
    name: 'route66',
    uri: gameUri + 'route66',
    description:
        'route66_desc',
    image: 'route66.webp',
    thumb: 'route66-thumb.webp',
    achievement: 'goldenbiker',
    isNew: false,
    battle: true,
    order: 14,
  },
  muertos: {
    title: 'Dia De Muertos',
    name: 'muertos',
    uri: gameUri + 'muertos',
    description:
        'muertos_desc',
    image: 'muertos.webp',
    thumb: 'muertos-thumb.webp',
    achievement: 'gravegambler',
    battle: true,
    order: 15,
  },
  legends: {
    title: 'Northern Legends',
    name: 'legends',
    uri: gameUri + 'legends',
    description:
        'cartel_desc',
    image: 'legends.webp',
    thumb: 'legends-thumb.webp',
    achievement: 'slotlegend',
    battle: true,
    order: 16,
  },
  tokyorun: {
    title: 'Tokyo Run',
    name: 'tokyorun',
    uri: gameUri + 'tokyorun',
    description:
        'tokio_desc',
    image: 'tokyorun.webp',
    thumb: 'tokyorun-thumb.webp',
    achievement: 'heistclown',
    battle: true,
    order: 17
  },
  lajam: {
    title: 'L.A. Jam',
    name: 'lajam',
    uri: gameUri + 'lajam',
    description:
        'lajam_desc',
    image: 'lajam.webp',
    thumb: 'lajam-thumb.webp',
    achievement: 'mcslotter',
    isNew: false,
    battle: true,
    order: 18,
  },
  apachetrail: {
    title: 'Apache Trail',
    name: 'apachetrail',
    uri: gameUri + 'apachetrail',
    description:
        'apache_desc',
    image: 'apachetrail.webp',
    achievement: 'grandchief',
    thumb: 'apachetrail-thumb.webp',
    isNew: false,
    battle: true,
    order: 19,
  },
  hulaspin: {
    title: 'Hulaspin',
    name: 'hulaspin',
    uri: gameUri + 'hulaspin',
    description:
        'hulaspin_desc',
    image: 'hulaspin.webp',
    achievement: 'hulaace',
    thumb: 'hulaspin-thumb.webp',
    isNew: false,
    battle: true,
    order: 20,
  },
  copacabana: {
    title: 'Copacabana',
    name: 'copacabana',
    uri: gameUri + 'copacabana',
    description:
        'copa_desc',
    image: 'copacabana.webp',
    achievement: 'carioca',
    thumb: 'copacabana-thumb.webp',
    isNew: false,
    battle: true,
    order: 21,
  },
  imperialrome: {
    title: 'Imperial Rome',
    name: 'imperialrome',
    uri: gameUri + 'imperialrome',
    description:
        'imperialrome_desc',
    image: 'imperialrome.webp',
    achievement: 'caesar',
    thumb: 'imperialrome-thumb.webp',
    isNew: false,
    battle: true,
    order: 22,
  },
  ninjastrike: {
    title: 'Ninja Strike',
    name: 'ninjastrike',
    uri: gameUri + 'ninjastrike',
    description:
        'ninjastrike_desc',
    image: 'ninja_strike.webp',
    achievement: 'shadowwarrior',
    thumb: 'ninja_strike-thumb.webp',
    isNew: false,
    battle: true,
    order: 23,
  },
  parisparis: {
    title: 'Paris Paris',
    name: 'parisparis',
    uri: gameUri + 'parisparis',
    description:
        'parisparis_desc',
    image: 'parisparis.webp',
    achievement: 'sacrebleuplayer',
    thumb: 'parisparis-thumb.webp',
    isNew: false,
    battle: true,
    order: 24,
  },
  sherlock: {
    title: 'Sherlock',
    name: 'sherlock',
    uri: gameUri + 'sherlock',
    description:
        'sherlock_desc',
    image: 'sherlock.webp',
    achievement: '',
    thumb: 'sherlock-thumb.webp',
    isNew: false,
    isSoon: true,
    isCascading: true,
    order: 25,
    testing: true
  },
  bollywood: {
    title: 'Bollywood Jackpot',
    name: 'bollywood',
    uri: gameUri + 'bollywood',
    description:
        'bollywood_desc',
    image: 'bollywood.webp',
    achievement: 'superstar',
    thumb: 'bollywood-thumb.webp',
    isNew: false,
    isCascading: false,
    order: 26,
    battle: true,
  },
  goldofgods: {
    title: 'Gold of Gods',
    name: 'goldofgods',
    uri: gameUri + 'goldofgods',
    description:
        'goldofgods_desc',
    image: 'goldofgods.webp',
    achievement: 'godluck',
    thumb: 'goldofgods_thumb.webp',
    isNew: true,
    isCascading: false,
    order: 27,
    battle: true,
  },
  mysterycircus: {
    title: 'Mystery Circus',
    name: 'mysterycircus',
    uri: 'mysterycircus',
    description:
        'mysterycircus_desc',
    image: 'mysterycircus.webp',
    achievement: '',
    thumb: 'mysterycircus_thumb.webp',
    isNew: false,
    isSoon: true,
    testing: true,
    battle:false,
    order: 28,
  },
};

export const scratchCards = {
  panda: {
    title: 'Panda Party',
    name: 'panda',
    uri: gameUri + 'panda',
    description:
        'scratch_high_vol_desc',
    image: 'panda.webp',
    thumb: 'panda.webp',
    cardPrice: 10,
    diamondWin: 10000,
    isNew: false,
    order: 1,
  },
  buffalo: {
    title: 'Buffalo Mania',
    name: 'buffalo',
    uri: gameUri + 'buffalo',
    description:
        'scratch_high_vol_desc',
    image: 'buffalo.webp',
    thumb: 'buffalo.webp',
    cardPrice: 20,
    diamondWin: 20000,
    isNew: false,
    order: 2
  },
  hippo: {
    title: 'Hippo Rama',
    name: 'hippo',
    uri: gameUri + 'hippo',
    description:
        'scratch_high_vol_desc',
    image: 'hippo.webp',
    thumb: 'hippo.webp',
    cardPrice: 50,
    diamondWin: 50000,
    isNew: false,
    order: 3
  },
  cheetah: {
    title: 'Cheetah Chasers',
    name: 'cheetah',
    uri: gameUri + 'cheetah',
    description:
        'scratch_high_vol_desc',
    image: 'cheetah.webp',
    thumb: 'cheetah.webp',
    cardPrice: 100,
    diamondWin: 100000,
    isNew: false,
    order: 4
  },
  rhino: {
    title: 'Rhino Rampage',
    name: 'rhino',
    uri: gameUri + 'rhino',
    description:
        'scratch_very_high_vol_desc',
    image: 'rhino.webp',
    thumb: 'rhino.webp',
    cardPrice: 200,
    diamondWin: 250000,
    isNew: false,
    order: 5
  },
  elephant: {
    title: 'Elephant Frenzy',
    name: 'elephant',
    uri: gameUri + 'elephant',
    description:
        'scratch_very_high_vol_desc',
    image: 'elephant.webp',
    thumb: 'elephant.webp',
    cardPrice: 500,
    diamondWin: 500000,
    isNew: false,
    order: 6
  },
  lion: {
    title: 'Lion\'s Roar',
    name: 'lion',
    uri: gameUri + 'lion',
    description:
        'scratch_very_high_vol_desc',
    image: 'lion.webp',
    thumb: 'lion.webp',
    cardPrice: 1000,
    diamondWin: 1000000,
    isNew: false,
    order: 7
  },
  tiger: {
    title: 'Tiger Strike',
    name: 'tiger',
    uri: gameUri + 'tiger',
    description:
        'scratch_very_high_vol_desc',
    image: 'tiger.webp',
    thumb: 'tiger.webp',
    cardPrice: 5000,
    diamondWin: 2000000,
    isNew: false,
    order: 8
  },
  velociraptor: {
    title: 'Veloci-Win',
    name: 'velociraptor',
    uri: gameUri + 'velociraptor',
    description:
        'scratch_extreme_vol_desc',
    image: 'velociraptor.webp',
    thumb: 'velociraptor.webp',
    cardPrice: 7000,
    diamondWin: 3000000,
    isNew: false,
    order: 9
  },
  spinosaurus: {
    title: 'Spino-Scratch',
    name: 'spinosaurus',
    uri: gameUri + 'spinosaurus',
    description:
        'scratch_extreme_vol_desc',
    image: 'spinosaurus.webp',
    thumb: 'spinosaurus.webp',
    cardPrice: 10000,
    diamondWin: 4000000,
    isNew:false,
    order: 10
  },
  triceratops: {
    title: 'Tri-Win-Tops',
    name: 'triceratops',
    uri: gameUri + 'triceratops',
    description:
        'scratch_extreme_vol_desc',
    image: 'triceratops.webp',
    thumb: 'triceratops.webp',
    cardPrice: 15000,
    diamondWin: 6000000,
    isNew:false,
    order: 11
  },
  stegosaurus: {
    title: 'Stego-Smash',
    name: 'stegosaurus',
    uri: gameUri + 'stegosaurus',
    description:
        'scratch_extreme_vol_desc',
    image: 'stegosaurus.webp',
    thumb: 'stegosaurus.webp',
    cardPrice: 20000,
    diamondWin: 9000000,
    isNew:false,
    order: 12
  },
  ankylosaurus: {
    title: 'Ankylo-Gold',
    name: 'ankylosaurus',
    uri: gameUri + 'ankylosaurus',
    description:
        'scratch_extreme_vol_desc',
    image: 'ankylosaurus.webp',
    thumb: 'ankylosaurus.webp',
    cardPrice: 25000,
    diamondWin: 11000000,
    isNew:false,
    order: 13
  },
  brontosaurus: {
    title: 'Bronto-Bucks',
    name: 'brontosaurus',
    uri: gameUri + 'brontosaurus',
    description:
        'scratch_extreme_vol_desc',
    image: 'brontosaurus.webp',
    thumb: 'brontosaurus.webp',
    cardPrice: 30000,
    diamondWin: 15000000,
    isNew:false,
    order: 14
  },
  diplodocus: {
    title: 'Diplo-Diamond',
    name: 'diplodocus',
    uri: gameUri + 'diplodocus',
    description:
        'scratch_extreme_vol_desc',
    image: 'diplodocus.webp',
    thumb: 'diplodocus.webp',
    cardPrice: 35000,
    diamondWin: 17000000,
    isNew:false,
    order: 15
  },
  trex: {
    title: 'T-Rex Riches',
    name: 'trex',
    uri: gameUri + 'trex',
    description:
        'scratch_extreme_vol_desc',
    image: 'trex.webp',
    thumb: 'trex.webp',
    cardPrice: 50000,
    diamondWin: 20000000,
    isNew:false,
    order: 16
  },
};

export const crashGames = {
  rocket: {
    title: 'Rocket Blast',
    name: 'rocket',
    uri: gameUri + 'rocket',
    description:
        'variable_vol_desc',
    image: 'rocket.webp',
    thumb: 'rocket-thumb.webp',
    isNew: false,
    order: 1,
  },
  monument: {
    title: 'Monument Valley - PVP',
    name: 'monument',
    uri: gameUri + 'monument',
    description:
        'variable_vol_desc',
    image: 'monument.webp',
    thumb: 'monument-thumb.webp',
    isNew: true,
    order: 2
  },
};

export const instantGames = {
  plinko: {
    title: 'Plinko',
    name: 'plinko',
    uri: gameUri + 'plinko',
    description:
        'variable_vol_desc',
    image: 'plinko.webp',
    thumb: 'plinko-webp.webp',
    order: 1,

  },
  penalty: {
    title: 'Penalty Worldcup',
    name: 'penalty',
    uri: gameUri + 'penalty',
    description:
        'variable_vol_desc',
    image: 'penalty.webp',
    thumb: 'penalty-thumb.webp',
    isNew: false,
    order: 2,
  },
  mayan: {
    title: 'Mayan Treasure',
    name: 'mayan',
    uri: gameUri + 'mayan',
    description:
        'variable_vol_desc',
    image: 'mayan.webp',
    thumb: 'mayan-thumb.webp',
    isNew:false,
    order: 3,
  },
};

export const spincity = {
  tycoon: {
    title: 'Spincity Tycoon',
    name: 'tycoon',
    uri: gameUri + 'spincitytycoon',
    description:
        'variable_vol_desc',
    image: 'spincity-banner.webp',
    thumb: 'plinko-thumb.webp',
    isNew: true,
    isSoon: true,
    order: 1,
    testing: true,
  },
};

export const allAchievements = {
  chad:{
    title: 'Slots Chad',
    name:'chad',
    description:'Earning all the badges',
    image:'chad.webp',
  },
  trophy:{
    title: 'Member Trophy',
    name:'trophy',
    description:'Take part in a Discord event',
    image:'trophy.webp',
  },
  master:{
    title:'Slots Master',
    name:'master',
    description:'Winning 10,000 slots games',
    image:'master.webp',
  },
  king:{
    title:'Slots King',
    name:'king',
    description:'Winning 50,000 slots games',
    image:'Slotsking.webp',
  },
  god:{
    title:'Slots God',
    name:'god',
    description:'Winning 100,000 slots games',
    image:'Slotsgod.webp',
  },
  godluck:{
    title:'Godluck',
    name:'godluck',
    description:`${achCnt} bets on Gold of Gods`,
    image:'godluck.webp',
  },
  trainwreck:{
    title:'Slots Trainwreck',
    name:'trainwreck',
    description:'Losing 10,000 slots games',
    image:'trainwreck.webp',
  },
  slotsohollic:{
    title:'Slotsohollic',
    name:'slotsohollic',
    description:'Playing 10,000 slots games',
    image:'slotsohollic.webp',
  },
  addict:{
    title:'Slots Addict',
    name:'addict',
    description:'Playing 50,000 slots games',
    image:'Slotaddict.webp',
  },
  tycoon:{
    title:'Slots Tycoon',
    name:'tycoon',
    description:'Playing 100,000 slots games',
    image:'Slottycoon.webp',
  },
  vip:{
    title:'VIP',
    name:'vip',
    description:'Spending more than $100 on chips',
    image:'vip.webp',
  },
  newyear2023:{
    title:'New Year 2023, New Me',
    name:'newyear2023',
    description:'Playing a game in the last week of 2022',
    image:'newyear2023.webp',
  },
  newyear2024:{
    title:'New Year 2024, New Me',
    name:'newyear2024',
    description:'Playing a game in the last week of 2023',
    image:'newyear2024.webp',
  },
  newyear2025:{
    title:'New Year 2025, New Me',
    name:'newyear2025',
    description:'Playing a game in the last week of 2024',
    image:'newyear2025.webp',
  },
  streak100:{
    title:'100 streak',
    name:'streak100',
    description:'100 daily streak',
    image:'streak100.webp',
  },
  bigwinner:{
    title:'Big winner',
    name:'bigwinner',
    description:'Win at least 10,000 chips in one spin',
    image:'bigwinner.webp',
  },
  chickendinner:{
    title:'Winner winner chicken dinner',
    name:'chickendinner',
    description:'Winning the Jackpot',
    image:'chickendinner.webp',
  },
  excalibur:{
    title:'Excalibur',
    name:'excalibur',
    description:`${achCnt} bets on king Arthur`,
    image:'excalibur.webp',
  },
  outlaw:{
    title:'Outlaw',
    name:'outlaw',
    description:`${achCnt} bets on Wild West`,
    image:'outlaw.webp',
  },
  kingpin:{
    title:'Narcos kingpin',
    name:'kingpin',
    description:`${achCnt} bets on Cartel`,
    image:'kingpin.webp',
  },
  zhuhou:{
    title:'Zhuhou',
    name:'zhuhou',
    description:`${achCnt} bets on Tiger land`,
    image:'zhuhou.webp',
  },
  ghost:{
    title:'Ghost',
    name:'ghost',
    description:`${achCnt} bets on Night of Fear`,
    image:'ghost.webp',
  },
  scarabs:{
    title:'Golden Scarabs',
    name:'scarabs',
    description:`${achCnt} bets on Cleopatra`,
    image:'scarabs.webp',
  },
  topsecret:{
    title:'Top Secret',
    name:'topsecret',
    description:`${achCnt} bets on SpyMaster`,
    image:'topsecret.webp',
  },
  shogun:{
    title:'Shogun',
    name:'shogun',
    description:`${achCnt} bets on Samurai Spirit`,
    image:'shogun.webp',
  },
  surfer:{
    title:'Silver Surfer',
    name:'surfer',
    description:`${achCnt} bets on Surfin' California`,
    image:'surfer.webp',
  },
  pirate:{
    title:'Pirate treasure',
    name:'pirate',
    description:`${achCnt} bets on Treasure Island Quest`,
    image:'pirate.webp',
  },
  jungleking:{
    title:'King of the jungle',
    name:'jungleking',
    description:`${achCnt} bets on The Big Five`,
    image:'jungleking.webp',
  },
  genie:{
    title:'Genie',
    name:'genie',
    description:`${achCnt} bets on Aladdin`,
    image:'genie.webp',
  },
  progambler:{
    title:'Pro Gambler',
    name:'progambler',
    description:`${achCnt} bets on Vegas Classic`,
    image:'progambler.webp',
  },
  goldenbiker:{
    title:'Golden Biker',
    name:'goldenbiker',
    description:`${achCnt} bets on Route66`,
    image:'goldenbiker.webp',
  },
  gravegambler:{
    title:'Graveyard Gambler',
    name:'gravegambler',
    description:`${achCnt} bets on Dia De Muertos`,
    image:'gravegambler.webp',
  },
  slotlegend:{
    title:'Slot Legend',
    name:'slotlegend',
    description:`${achCnt} bets on Northern Legends`,
    image:'slotlegend.webp',
  },
  heistclown:{
    title:'Heist Clown',
    name:'heistclown',
    description:`${achCnt} bets on Tokyo Run`,
    image:'heistclown.webp',
  },
  mcslotter:{
    title:'M.C. Slotter',
    name:'mcslotter',
    description:`${achCnt} bets on L.A. Jam`,
    image:'mcslotter.webp',
  },
  grandchief:{
    title:'Grand Chief',
    name:'grandchief',
    description:`${achCnt} bets on Apache Trail`,
    image:'grandchief.webp',
  },
  hulaace:{
    title:'Hula Ace',
    name:'hulaace',
    description:`${achCnt} bets on Hula Ace`,
    image:'hulaace.webp',
  },
  carioca:{
    title:'Carioca',
    name:'carioca',
    description:`${achCnt} bets on Copacabana`,
    image:'carioca.webp',
  },
  caesar:{
    title:'Caesar',
    name:'caesar',
    description:`${achCnt} bets on Imperial Rome`,
    image:'caesar.webp',
  },
  shadowwarrior:{
    title:'Shadowwarrior',
    name:'shadowwarrior',
    description:`${achCnt} bets on Ninja Strike`,
    image:'shadowwarrior.webp',
  },
  sacrebleuplayer:{
    title:'Sacrebleu Player',
    name:'sacrebleuplayer',
    description:`${achCnt} bets on Paris Paris`,
    image:'sacrebleuplayer.webp',
  },
  superstar:{
    title:'Superstar',
    name:'superstar',
    description:`${achCnt} bets on Bollywood`,
    image:'superstar.webp',
  },
}

export const digitalPrizes = {
  'nitrofull':{
    name: "Full Nitro",
    collection: "Discord",
    codeInstructions: "- Copy and past the link in your internet browser.\n" +
        "- Follow the instructions.",
    img: "nitro-full.webp"
  },
  'nitroclassic': {
    name: "Classic Nitro",
    collection: "Discord",
    codeInstructions: "- Copy and past the link in your internet browser.\n" +
        "- Follow the instructions.",
    img: "nitro-classic.webp"
  },
  'csdeals': {
    name: "Giftcard",
    collection: "CS.Deals",
    codeInstructions: "- Log in to CS. Deals.\n" +
        "- Go to cs. deals/wallet.\n" +
        "- Choose the \"Gift card\" deposit option.\n" +
        "- Enter the key.",
    img: "giftcard-csdeals.webp"
  },
  'g2a': {
    name: "Giftcard",
    collection: "G2A",
    codeInstructions: "- Login to your G2A account.\n" +
        "- From the G2A Wallet menu in the top right select \"Add G2A Gift Card\"\n" +
        "- Enter your code and click \"Add to your balance\"\n" +
        "- A message should be displayed about a successful redemption.",
    img: "giftcard-g2a.webp"
  },
  'nintendo5': {
    name: " Giftcard",
    collection: "Nintendo",
    codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
        "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
        "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
        "- Enter your 16-digit download code and then select, Next\n" +
        "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
    img: "giftcard-nintendo5.webp"
  },
  'nintendo10': {
    name: "Giftcard",
    collection: "Nintendo",
    codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
        "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
        "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
        "- Enter your 16-digit download code and then select, Next\n" +
        "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
    img: "giftcard-nintendo10.webp"
  },
  'nintendo20': {
    name: "Giftcard",
    collection: "Nintendo",
    codeInstructions: "- Using a browser, visit ec.nintendo.com/redeem/#/\n" +
        "- Select Sign in and enter your Nintendo Account e-mail address and password, and then select Sign in again.\n" +
        "- If you are already signed in, review the user name in the top-right corner to confirm you are signed in with the correct Nintendo Account\n" +
        "- Enter your 16-digit download code and then select, Next\n" +
        "- Re-enter your Nintendo Account password when prompted, then select Redeem to complete the process.",
    img: "giftcard-nintendo20.webp"
  },
  'roblox': {
    name: "Giftcard",
    collection: "Roblox",
    codeInstructions: "- Log into your account on a browser.\n" +
        "- Go to the Code Redemption Page.\n" +
        "- Enter your code in the box.\n" +
        "- Click Redeem.",
    img: "giftcard-roblox.webp"
  },
  'steam': {
    name: "Giftcard",
    collection: "Steam",
    codeInstructions: "- Visit store.steampowered.com/account/redeemwalletcode in your browser and sign in into your account.\n" +
        "- Enter the code into the Steam Wallet Code field.\n" +
        "- Follow the instructions.",
    img: "giftcard-steam.webp"
  },
  'bronzeMonthlyCard': {
    name: "Bronze VIP Card",
    collection: "Blockspin",
    codeInstructions: "- Click on 'Chips'.\n" +
        "- Click 'Redeem'.\n" +
        "- Enter the code.\n" +
        "- Click 'Send code'.",
    img: "vip_card_prize_bronze.webp"
  },
  'silverMonthlyCard': {
    name: "Silver VIP Card",
    collection: "Blockspin",
    codeInstructions: "- Click on 'Chips'.\n" +
        "- Click 'Redeem'.\n" +
        "- Enter the code.\n" +
        "- Click 'Send code'.",
    img: "vip_card_prize_silver.webp"
  },
  'goldMonthlyCard': {
    name: "Gold VIP Card",
    collection: "Blockspin",
    codeInstructions: "- Click on 'Chips'.\n" +
        "- Click 'Redeem'.\n" +
        "- Enter the code.\n" +
        "- Click 'Send code'.",
    img: "vip_card_prize_gold.webp"
  },
}

export const titles = {
  0: "Newbie",
  10: "Rookie",
  20: "Original Sloter",
  30: "Total Slotter",
  40: "Amazing Slotter",
  50: "Wow Slotter",
  60: "Pro Slotter",
  69: "Nice Slotter",
  80: "Godlike",
  90: "Tryhard Gamer",
  100: "The GOAT!",
};


export const memberships = {
  'bronze':{
    name:"30 Days Bronze VIP Membership",
    priceUsd: 4.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Perks",
    img: "vip-bronze.webp"
  },
  'silver':{
    name:"30 Days Silver VIP Membership",
    priceUsd: 19.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Perks",
    img: "vip-silver.webp"
  },
  'gold':{
    name:"30 Days Gold VIP Membership",
    priceUsd: 49.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Perks",
    img: "vip-gold.webp"
  },
}
export const boost = {
  'insurance1Day':{
    name:"1 Day Insurance",
    priceUsd: 4.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Secure your daily streak with a one-day insurance plan that offers protection in case you miss a day.",
    img: "insurance1.webp"
  },
  'insurance7Day':{
    name:"7 Days Insurance",
    priceUsd: 9.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"The 7-day insurance pack offers ultimate protection that can be triggered either independently or " +
        "consecutively, giving you peace of mind and flexibility.",
    img: "insurance7.webp"
  },
  'xpBoost':{
    name:"XP Boost",
    priceUsd: 1,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Earn x2 times more experience when you play.\n\nDuration: 6 hours.",
    img: "xpboost.webp"
  },
  'megaXpBoost':{
    name:"Mega XP Boost",
    priceUsd: 3,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Earn x5 times more experience when you play.\n\nDuration: 6 hours.",
    img: "xpmegaboost.webp"
  },
  'xpFreeze':{
    name:"XP Freeze",
    priceUsd: 19.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"Dont lose your XP and daily streak, the freeze save your Streak and XP until you spin again.\n\nDuration: Unlimited.",
    img: "freeze.webp"
  },
  'xpRecovery':{
    name:"XP Recovery",
    priceUsd: 49.99,
    priceWax: 100,
    priceGems: 100000,
    priceSolana: 10,
    amount:1,
    description:"You recover your lost XP amount",
    img: "recovery.webp"
  },
}

export const jackbot = {
  invite:"https://discord.com/api/oauth2/authorize?client_id=1093157226165837884&permissions=51200&redirect_uri=https%3A%2F%2Fdiscord.gg%2FK3AFXtkajS&response_type=code&scope=guilds.join%20bot",
  page:"https://blockspingaming.com/jackbot"
}
